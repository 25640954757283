<template>
    <div class="page-tv-entitlements container">
        <div class="row">
            <div class="col-12">
                <h1 class="heading">TV Entitlements</h1>
            </div>

            <div class="col-8 col-tab-12">
                <app-table
                    class="entitlements-table"

                    :sort-value="filter.sort"
                    :sort-options="sort"
                    @sort-change="onSortChange"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading"

                    @change-tv-entitlement="onChangeTVEntitlement"
                    @remove-tv-entitlement="onRemoveTVEntitlement"
                />

                <app-pagination
                    v-if="pagination.total"

                    v-model="pagination.page"

                    :total="pagination.total"
                    :limit="pagination.limit"
                    :page-range="pagination.range"

                    @change="onPageChange"
                />
            </div>

            <div class="col-4 col-tab-12">
                <app-adding-banner
                    header="Add entitlements"
                    description="Easily adds an entitlements to your service portfolio"
                    image="service"
                    action="add-tv-entitlement"

                    @add-tv-entitlement="onAddTVEntitlement"
                />
            </div>
        </div>

        <app-dialog-add-tv-entitlement
            v-if="dialogs.entitlement.add.show"
            v-model="dialogs.entitlement.add.show"

            @confirm="onDialogAddTVEntitlementConfirm"
            @close="onDialogAddTVEntitlementClose"
        />

        <app-dialog-change-tv-entitlement
            v-if="dialogs.entitlement.change.show"
            v-model="dialogs.entitlement.change.show"

            :uuid="dialogs.entitlement.change.uuid"

            :name="dialogs.entitlement.change.name"
            :email="dialogs.entitlement.change.email"
            :entitlement-id="dialogs.entitlement.change.entitlement_id"

            @confirm="onDialogChangeTVEntitlementConfirm"
            @close="onDialogChangeTVEntitlementClose"
        />

        <app-dialog-entitlement-removal
            v-if="dialogs.entitlement.remove.show"
            v-model="dialogs.entitlement.remove.show"

            :uuid="dialogs.entitlement.remove.uuid"

            @confirm="onDialogEntitlementRemovalConfirm"
            @close="onDialogEntitlementRemovalClose"
        />
    </div>
</template>

<script>
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'
import appAddingBanner from '@/components/app-adding-banner'

import appDialogEntitlementRemoval from './components/app-dialog-entitlement-removal'
import appDialogAddTVEntitlement from './components/app-dialog-add-tv-entitlement'
import appDialogChangeTVEntitlement from './components/app-dialog-change-tv-entitlement'

export default {
    components: {
        appTable,
        appPagination,
        appAddingBanner,

        appDialogEntitlementRemoval,
        'app-dialog-add-tv-entitlement': appDialogAddTVEntitlement,
        'app-dialog-change-tv-entitlement': appDialogChangeTVEntitlement,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Name',  title: 'Name',   },
                { field: 'Email', title: 'E-mail', },
            ],

            cols: [
                { key: 'name',           title: 'Name',           sort: { field: 'Name'  },  highlight: true,   },
                { key: 'email',          title: 'E-mail',         sort: { field: 'Email' },                     },
                { key: 'entitlement-id', title: 'Entitlement ID',                                               },
                { key: 'edit',           action: 'change-tv-entitlement', icon: 'edit',   behavior: 'detached', },
                { key: 'remove',         action: 'remove-tv-entitlement', icon: 'remove', behavior: 'detached', },
            ],
            rows: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            dialogs: {
                entitlement: {
                    add: {
                        show: false,
                    },

                    change: {
                        show: false,

                        uuid: null,

                        name: null,
                        email: null,
                        entitlement_id: null,
                    },

                    remove: {
                        show: false,

                        uuid: null,
                    },
                },
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getTVEntitlements()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getTVEntitlements()
        },

        onPageChange(page) {
            this.getTVEntitlements(page)
        },

        getTVEntitlements(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('getTVEntitlements', { params })
                .then(({ PageInfo, Entitlements }) => {

                    // console.log(PageInfo)
                    // console.log(Entitlements)

                    this.rows = Entitlements.map( item => ({
                        uuid: item.UUID,

                        name: item.Name,

                        email: item.Email,

                        'entitlement-id': item.EntitlementId,
                    }))

                    const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)

                    this.rows = []

                    this.loading = false
                })
        },

        /**
         * ADD
         */

        onAddTVEntitlement() {
            this.dialogs.entitlement.add.show = true
        },

        onDialogAddTVEntitlementConfirm() {
            this.getTVEntitlements()

            this.dialogs.entitlement.add.show = false
        },

        onDialogAddTVEntitlementClose() {
            this.dialogs.entitlement.add.show = false
        },

        /**
         * CHANGE
         */

        onChangeTVEntitlement(row) {
            this.dialogs.entitlement.change.show = true

            this.dialogs.entitlement.change.uuid = row.uuid

            this.dialogs.entitlement.change.name = row.name
            this.dialogs.entitlement.change.email = row.email
            this.dialogs.entitlement.change.entitlement_id = row['entitlement-id']
        },

        onDialogChangeTVEntitlementConfirm() {
            this.getTVEntitlements()

            this.dialogs.entitlement.change.show = false

            this.dialogs.entitlement.change.uuid = null

            this.dialogs.entitlement.change.name = null
            this.dialogs.entitlement.change.email = null
            this.dialogs.entitlement.change.entitlement_id = null
        },

        onDialogChangeTVEntitlementClose() {
            this.dialogs.entitlement.change.show = false

            this.dialogs.entitlement.change.uuid = null

            this.dialogs.entitlement.change.name = null
            this.dialogs.entitlement.change.email = null
            this.dialogs.entitlement.change.entitlement_id = null
        },

        /**
         * REMOVE
         */

        onRemoveTVEntitlement(row) {
            this.dialogs.entitlement.remove.uuid = row.uuid
            this.dialogs.entitlement.remove.show = true
        },

        onDialogEntitlementRemovalConfirm() {
            this.getTVEntitlements()

            this.dialogs.entitlement.remove.uuid = null
            this.dialogs.entitlement.remove.show = false
        },

        onDialogEntitlementRemovalClose() {
            this.dialogs.entitlement.remove.uuid = null
            this.dialogs.entitlement.remove.show = false
        },
    },
}
</script>

<style lang="scss">
.page-tv-entitlements {
    padding-bottom: 80px;

    h1 {
        margin-bottom: 48px;
    }

    .entitlements-table {
        @include table-cols-width((170px, 240px, 300px, 48px, 48px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }

    .app-dialog {
        z-index: $z-index-app-navigation-bar + 1;
    }
}

@media (max-width: $tablet-size) {
    .page-tv-entitlements {

        .entitlements-table {
            @include table-cols-width((160px, 200px, 220px, 48px, 48px), true);
        }

        .app-adding-banner {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-tv-entitlements {
        padding-bottom: 64px;

        .entitlements-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-adding-banner {
            margin-top: 15px;
        }
    }
}
</style>