<template>
    <app-dialog-info
        class="app-dialog-entitlement-removal"
        v-if="show"
        v-model="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Entitlement removal</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="wrap">
                <app-error v-model="error" :message="error"></app-error>

                <div class="content">
                    <p>You have selected to remove this Entitlement.</p>
                    <p>If this was the action that you want to do, please confirm your choice or cancel and return to the page.</p>
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        uuid: { type: String, default: null },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
    },

    data() {
        return {
            loading: false,

            error: null,
        }
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },

    methods: {
        confirm() {
            this.error = null
            this.loading = true

            this.$store.dispatch('deleteTVEntitlement', { uuid: this.uuid })
                .then(response => {
                    console.log(response)

                    this.$emit('confirm')
                    this.loading = false
                })
                .catch(error => {
                    this.error = errMessage(error)
                    this.loading = false
                })
        },

        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-entitlement-removal {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-entitlement-removal {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>