<template>
    <app-dialog-info
        class="app-dialog-change-tv-entitlement"
        v-if="show"
        v-model="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Change TV Entitlement</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="form">
                <div class="group">
                    <app-input
                        v-model="fields.name"
                        label="Name"
                        :error="errors.name"
                    />
                </div>

                <div class="group">
                    <app-input
                        v-model="fields.email"
                        label="E-mail"
                        :error="errors.email"
                    />
                </div>

                <div class="group">
                    <app-input
                        v-model="fields.entitlement_id"
                        label="Entitlement ID"
                        :error="errors.entitlement_id"
                    />
                </div>

                <div class="actions">
                    <button class="btn btn-primary btn-left" @click="confirm">Save</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'

import EntitlementValidator from '@/validators/entitlement-validator'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value: { required: true, },
        maxWidth: { type: [Number, String], default: 610 },

        uuid: { type: String, default: null },

        name: { type: String, default: null },
        email: { type: String, default: null },
        entitlementId: { type: String, default: null },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
        appInput,
    },

    data() {
        return {
            fields: {
                uuid: '',

                name: '',
                email: '',
                entitlement_id: '',
            },

            errors: {
                name: '',
                email: '',
                entitlement_id: '',
            },

            loading: false,

            error: null,
        }
    },

    computed: {
        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.fields.uuid = this.uuid

            this.fields.name = this.name
            this.fields.email = this.email
            this.fields.entitlement_id = this.entitlementId
        },

        validation() {
            let errors = 0
            this.errors = {}

            const fields = [
                {
                    rule: 'name',
                    message: 'Name is invalid',
                    value: this.fields.name,
                    key: 'name',
                },
                {
                    rule: 'email',
                    message: 'E-mail is invalid',
                    value: this.fields.email,
                    key: 'email',
                },
                {
                    rule: 'id',
                    message: 'Entitlement ID is invalid',
                    value: this.fields.entitlement_id,
                    key: 'entitlement_id',
                },
            ]

            for(let field of fields) {
                if (EntitlementValidator.isRuleExists(field.rule)) {
                    if (EntitlementValidator.isInvalid(field.rule, field.value, field.message)) {
                        this.errors[ field.key ] = EntitlementValidator.getError()
                        errors++;
                    }
                }
            }

            return !errors
        },

        confirm() {
            if (this.validation()) {

                this.error = null
                this.loading = true

                const data = {
                    Name:          this.fields.name,
                    Email:         this.fields.email,
                    EntitlementId: this.fields.entitlement_id,
                }

                this.$store.dispatch('changeTVEntitlement', { uuid: this.fields.uuid, data })
                    .then(response => {
                        console.log(response)

                        this.$emit('confirm')
                        this.loading = false
                    })
                    .catch(error => {
                        this.error = errMessage(error)
                        this.loading = false
                    })
            }
        },

        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-change-tv-entitlement {
    .app-dialog-body {
        padding: 80px 80px 72px;

        h2 {
            margin-bottom: 70px;
        }

        .form {
            width: 100%;

            .group {
                margin-bottom: 30px;
            }

            .actions {
                display: flex;
                justify-content: space-between;

                margin-top: 34px;

                .btn {
                    max-width: 210px;

                    margin: 0 15px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-change-tv-entitlement {
        .app-dialog-body {
            padding: 64px 24px;

            h2 {
                margin-bottom: 40px;
            }

            .form {
                .actions {
                    flex-direction: column;

                    margin-top: 32px;
                    padding: 0 16px;

                    .btn {
                        max-width: none;

                        margin: 0 0 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>